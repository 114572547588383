// Importez les composants nécessaires
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BookList from './components/BookList';
import Etape2Livre from './components/Etape2ivre';
import Etape3Livre from './components/Etape3Livre';
import ResetPassword from './components/ResetPassword';

const App = () => {
  useEffect(() => {
    checkTokenExpiration();
  }, []);

  const checkTokenExpiration = () => {
    const token = localStorage.getItem('token');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    if (token && tokenExpiration) {
      const currentTime = Date.now();
      const isTokenExpired = currentTime > parseInt(tokenExpiration, 10);

      if (isTokenExpired) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        <Navigate to="/login" />
      
      }
    }
  };

return (
  <Router>
    
    <Routes>
      <Route path="/admin/*" element={<AdminPanel />} />
      <Route path="/login" element={<Login /> }/>
      <Route path='/step2/:book_id' element={<Etape2Livre />} />
      <Route path='/etape3Livre/:book_id' element={<Etape3Livre />} />      
      <Route path="/reset-password" element={<ResetPassword /> }/>
      {/*  livres */}
      <Route path="book-list" exact component={BookList} />
      {/* Ajoutez une redirection par défaut vers /login si l'utilisateur n'est pas authentifié */}
      <Route index element={<Navigate to="/login" />} />
      
    </Routes>
    <ToastContainer />
  </Router>
);

};

export default connect()(App);
