import { useEffect, useState } from 'react';
import axios from 'axios';
import { APP_URL } from '../../URL';
import { Button, Modal, Spin, Upload } from 'antd';
import { useRecoilState } from 'recoil';
import { modalCoverUpdateState, modalDataCoverUpdateState } from '../../actions/Atom';
import { PlusOutlined } from '@ant-design/icons';

const getBase64 = (file) => 
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function ShowCoverUpdate(){

    const [isModalOpenCover, setIsModalOpenCover] = useRecoilState(modalCoverUpdateState);
    const [modalDataCover, setModalDataCover] = useRecoilState(modalDataCoverUpdateState);

    const [categories, setCategies] = useState([]);
    const token = localStorage.getItem('token');
    const [titreLivre, setTitreLivre] = useState('');
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(false);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);


    const showModal = () => {
        setIsModalOpenCover(true);
      };
      const handleOk = () => {
        setIsModalOpenCover(false);
        setFileList([]);
      };
      const handleCancelAp = () => {
        setIsModalOpenCover(false);
        setFileList([]);
      };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };


      const handleChange = ({ fileList: newFileList }) => { setFileList(newFileList); setSelected(true); }
      const uploadButton = (
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button"
        >
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </button>
      );

      useEffect(() => {
        
      },[fileList,selected]);
    

    return (
        <>
      <Modal title={`Modifier la photo de couverture du livre "${modalDataCover.titre}"`} open={isModalOpenCover} footer={null} width={700} onCancel={handleCancelAp}>
       
      <center>
        <h6></h6>
        <img src={`${modalDataCover.couverture}`} style={{height:'100px', width:'100px', borderRadius:'100%'}} />
        {/* <img src={`${modalDataCover.url}`} style={{height:'100px', width:'100px', borderRadius:'100%'}} /> */}
      <hr />
      <Upload
            style={{ width: '100%', height: '300px' }}
            customRequest={(file) => {
              const token = localStorage.getItem('token')
             
              if (!token) {
                throw new Error('Token non trouvé');
              }
              return axios.post(`${APP_URL}add-cover-book/${modalDataCover.id}`, {
                file,
              }, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`,
                }
              }).then((response) => {
                console.log(response.data)
                setFileList(response.data);
                setModalDataCover(response.data);

                handleOk(); // on ferme la boite de dialogue
               
               
              }).catch((error) => {
                console.log(error.response.data);
              });
            }}

            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            name="file"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          </center>

          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
       
       
      </Modal>
        </>
    );
}