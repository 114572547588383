import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="sticky-footer" style={{ position: 'absolute', bottom: 0, width: '100%', background: 'green', height: '60px', color: 'white', zIndex: '9999' }}>
      <div className="container">
        <div className="text-center">
          <small>Copyright © AWOULAWOULA {currentYear}</small>
        </div>
      </div>
    </footer>
  );
  
};

export default Footer;
