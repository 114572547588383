
import axios from 'axios';
import { toast } from 'react-toastify';
import { APP_URL } from '../URL';


export const createBook = (bookData) => {
 

  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('titre',bookData.titre);
   

      console.log('VIEW ONE SHOT',bookData)
      const response = await axios.post(APP_URL+'livres', bookData, {
        headers: {
          //  'Content-Type': 'application/ld+json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assurez-vous d'avoir un token valide
        },
      });

      dispatch({
        type: 'CREATE_BOOK_SUCCESS',
        payload: response.data,
      });

      console.log('reponse du serveur', response.data);

      // Afficher une notification de succès
      toast.success('Livre créé avec succès!');
     

    } catch (error) {
     
      if (error.response) {
        // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);
    
        dispatch({
          type: 'CREATE_BOOK_FAILURE',
          payload: error.response.data,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la création du livre.');
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);
    
        dispatch({
          type: 'CREATE_BOOK_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la création du livre.');
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la configuration de la requête:', error.message);
    
        dispatch({
          type: 'CREATE_BOOK_FAILURE',
          payload: error.message,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la création du livre.');
      }
    }
  };
};


export const fetchBooksSuccess = (books) => ({
  type: 'FETCH_BOOKS_SUCCESS',
  payload: books,
});

export const fetchBooksFailure = (error) => ({
  type: 'FETCH_BOOKS_FAILURE',
  payload: error,
});

export const fetchBooks = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }

      const response = await axios.get(APP_URL + 'livres', {
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${token}`,
        },
      });

    
      dispatch(fetchBooksSuccess(response.data.datas));  // Dispatch l'action de succès
      console.log('nsssjjsjsjs',response.data);
      
    } catch (error) {
      console.log(error);
     
      dispatch(fetchBooksFailure(error.message));  // Dispatch l'action d'échec
      toast.error('Erreur lors de la lecture des livres.');
    }
  };

};

export const updateBook = (bookId, updatedBook) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }
      const response = await axios.put(
        `${APP_URL}livres/${bookId}`,
        updatedBook,
        {
          headers: {
            'Content-Type': 'application/ld+json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: 'UPDATE_BOOK_SUCCESS',
        payload: response.data,
      });
      toast.success('Livre  modifié avec succès!');
    } catch (error) {
      if (error.response) {
        // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);
    
        dispatch({
          type: 'UPDATE_BOOK_FAILURE',
          payload: error.response.data,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la modification du livre.');
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);
    
        dispatch({
          type: 'UPDATE_BOOK_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la création du livre.');
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la configuration de la requête:', error.message);
    
        dispatch({
          type: 'UPDATE_BOOK_FAILURE',
          payload: error.message,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la création du livre.');
      }
    }
  };
};

export const deleteBook = (bookId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }
      const response = await axios.delete(`${APP_URL}admin/delete-book/${bookId}`, {
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${token}`,
        }
      });
 
      dispatch({
        type: 'DELETE_BOOK_SUCCESS',
        payload: bookId,
      });
      toast.success('Livre  a été supprimer avec succès!');
    } catch (error) {
      if (error.response) {
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);
    
        dispatch({
          type: 'DELETE_BOOK_FAILURE',
          payload: error.message,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la suppresion du livre.');
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);
    
        dispatch({
          type: 'DELETE_BOOK_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la suppression du livre.');
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la configuration de la requête:', error.message);
    
        dispatch({
          type: 'DELETE_BOOK_SUCCESS',
          payload: error.message,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la suppression du livre.');
      }
    }
  };
};

export const uploadPdf = (file,onUploadProgress) => async (dispatch) => {
  try {
    console.log('Upload PDF function is being executed');
    const formData = new FormData();
    formData.append('pdf', file);

    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token non trouvé');
    }
    const response = await axios.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
      onUploadProgress,
    });

    console.log('Upload PDF function executed successfully');
    dispatch({
      type: 'UPLOAD_PDF_SUCCESS',
      payload: response.data,
    });
  } catch (error) {
    toast.error('Error in Upload PDF function:', error.message);
    dispatch({
      type: 'UPLOAD_PDF_FAILURE',
      payload: error.message,
    });
  }
};
