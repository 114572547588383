import axios from 'axios';
import { toast } from 'react-toastify';
import { APP_URL } from '../URL';

// actions/ResetPasswordActions.js

// export const requestPasswordReset = (email) => {
//   return async (dispatch) => {
//     try {
//       // Effectuer la requête API pour demander la réinitialisation du mot de passe
//       const response = await axios.post(`${APP_URL}reset-password`, { email: email } ,{
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/ld+json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();

//       if (!response.ok) {
//         throw new Error(data.message || 'Erreur lors de la demande de réinitialisation du mot de passe');
//       }

//       // Dispatch de l'action de succès si la requête réussit
//       dispatch({ type: 'REQUEST_PASSWORD_RESET_SUCCESS', payload: data });
//     } catch (error) {
//       // Dispatch de l'action d'erreur si la requête échoue
//       dispatch({ type: 'REQUEST_PASSWORD_RESET_FAILURE', error: error.message });
//       throw error;
//     }
//   };
// };

export const requestPasswordReset = (email) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${APP_URL}reset-password`, { email: email }, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      });

      dispatch({
        type: 'REQUEST_PASSWORD_RESET_SUCCESS',
        payload: response.data,
      });

      console.log('Réponse du serveur', response.data);
      toast.success(`Un code a été envoyé à l'adresse : ${email}`);
    } catch (error) {
      console.error('Erreur capturée dans le bloc catch :', error);

      if (error.response) {
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);

        dispatch({
          type: 'REQUEST_PASSWORD_RESET_FAILURE',
          payload: error.response.data,
        });

        // Afficher une notification d'échec
        toast.error(`Nous n'avons pas trouvé de compte avec l'adresse email : ${email}`);
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);

        dispatch({
          type: 'REQUEST_PASSWORD_RESET_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });

        // Afficher une notification d'échec
        toast.error('Erreur lors de l\'envoi du mail');
      } else {
        // Une autre erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la configuration de la requête:', error.message);

        dispatch({
          type: 'REQUEST_PASSWORD_RESET_FAILURE',
          payload: error.message,
        });

        // Afficher une notification d'échec
        toast.error('Erreur inconnue');
      }
    }
  };
};


export const verifyResetCode = (resetCode,newPassword) => {
    return async (dispatch)=>{
         try {
            
             const response = await axios.post(`${APP_URL}check-code-reinit-password`, {
              reset_code: resetCode,
              new_password: newPassword
            } , {
                 headers: {
                   'Content-Type': 'application/ld+json',
                 },
               });
 
               dispatch({
                 type: "VERIFY_RESET_CODE_SUCCESS",
                 payload : response.data
               })
              console.log('reponse du serveur', response.data);
              toast.success('code bon');
        
         } catch (error) {
             if(error.response){
                 console.error('Réponse du serveur avec erreur:', error.response.data);
                 console.error('Statut de la réponse:', error.response.status);
                 console.error('En-têtes de la réponse:', error.response.headers);
     
         dispatch({
           type: 'VERIFY_RESET_CODE_FAILURE',
           payload: error.response.data,
         });
     
         // Afficher une notification d'échec
         toast.error('aucune correspondance pour ce code. soit il a expiré, soit l\'utilisateur lié a ce code n\'existe pas ou simplement le code est érroné');
 
             } else if(error.request){
                         // La requête a été faite, mais aucune réponse n'a été reçue
         console.error('Pas de réponse reçue du serveur:', error.request);
     
         dispatch({
           type: 'REQUEST_PASSWORD_RESET_FAILURE',
           payload: 'Pas de réponse reçue du serveur',
         });
     
         // Afficher une notification d'échec
         toast.error('Erreur lors de l envoi du code');
 
             }
         }
 }
  
 }
 
