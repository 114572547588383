import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './ResetPassword.css';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { requestPasswordReset, verifyResetCode } from '../actions/ResetPasswordActions';
import { toast } from 'react-toastify';

const ResetPasswordForm = ({ requestPasswordReset, verifyResetCode, resetCodeVerified, resetSuccess, error }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [emailSent, setEmailSent] = useState(false); // Ajout d'un état pour suivre le succès de la demande de réinitialisation
  const navigate = useNavigate();

  const handleEmailSubmit = async () => {
    setIsLoading(true);
    try {
      await requestPasswordReset(email);
      setEmailSent(true); // Mise à jour de l'état lorsque l'email a été envoyé avec succès
      setStep(2); // Ne passe à l'étape suivante que si l'action réussit
    } catch (error) {
      // L'erreur est déjà gérée dans l'action, donc pas besoin de gérer ici
      setEmailSent(false); // Mise à jour de l'état lorsque l'email n'a pas été envoyé
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationSubmit = async () => {
    setIsLoading1(true);
    try {
      await verifyResetCode(resetCode, newPassword);
      toast.success("Mot de passe réinitialisé avec succès !");
      navigate('/login');
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la réinitialisation du mot de passe.");
    } finally {
      setIsLoading1(false);
    }
  };

  const handleCancel = () => {
    navigate('/login'); 
  };

  useEffect(() => {
    let timer;
    if (isLoading1) {
      timer = setTimeout(() => {
        setIsLoading1(false);
      }, 3000); 
    }
    return () => clearTimeout(timer);
  }, [isLoading1]);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        setIsLoading(false);
      }, 3000); 
    }
    return () => clearTimeout(timer);
  }, [isLoading]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ width: '400px', paddingRight: '600px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        {step === 1 && (
          <Form name="email-form" onFinish={handleEmailSubmit} layout="vertical" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', width: '600px' }}>
            <div className="text-center" style={{ fontFamily: "arial" }}>
              <h3>Réinitialiser le mot de passe</h3>
            </div>
            <Form.Item
              label="Adresse e-mail"
              name="email"
              rules={[{ required: true, message: 'Veuillez saisir votre adresse e-mail!' }]}
            >
              <Input placeholder="Entrez votre adresse e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: "#06dc0d" }} loading={isLoading}>
                Envoyer le code de vérification
              </Button>
              <Button onClick={handleCancel} style={{ width: '100%', marginTop: '10px' }}>
                Annuler et retourner à la connexion
              </Button>
            </Form.Item>
          </Form>
        )}

        {step === 2 && emailSent && ( // Vérifiez l'état emailSent avant de passer à l'étape 2
          <Form name="verification-form" onFinish={handleVerificationSubmit} layout="vertical" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', width: '600px' }}>
            <div className="text-center" style={{ fontFamily: "arial" }}>
              <h3>Nouveau mot de passe</h3>
            </div>
            <Form.Item
              label="Code de vérification"
              name="resetCode"
              rules={[{ required: true, message: 'Veuillez saisir le code de vérification!' }]}
            >
              <Input placeholder="Entrez le code de vérification" value={resetCode} onChange={(e) => setResetCode(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Nouveau mot de passe"
              name="newPassword"
              rules={[{ required: true, message: 'Veuillez saisir votre nouveau mot de passe!' }]}
            >
              <Input.Password placeholder="Entrez votre nouveau mot de passe" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: "#06dc0d" }} loading={isLoading1}>
                Réinitialiser le mot de passe
              </Button>
              <Button onClick={handleCancel} style={{ width: '100%', marginTop: '10px' }}>
                Annuler et retourner à la connexion
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    resetCodeVerified: state.reset.resetCodeVerified,
    resetSuccess: state.reset.resetSuccess,
    error: state.reset.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestPasswordReset: (email) => dispatch(requestPasswordReset(email)),
  verifyResetCode: (resetCode, newPassword) => dispatch(verifyResetCode(resetCode, newPassword))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
