import axios from "axios";
import React, { useEffect, useState } from "react";
import { APP_URL } from "../URL";
import { useNavigate, useParams } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { Button } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { bookIdState } from "../actions/Atom";
import { toast } from "react-toastify";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function Etape3Livre(){

    const [books, setBooks] = useState();
    const {book_id} = useParams();
    const [selected, setSelected] = useState(false);
    const navigate = useNavigate();
    const [titre, setTitre] = useState('');
    const [type, setType] = useState('');
    const [bookId, setBookId] = useRecoilState(bookIdState);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleNavbarClick = () => {
    navigate('/admin/book-list');
  };

  const handleChange = ({ fileList: newFileList }) => {setFileList(newFileList); setSelected(true) }
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

    useEffect(() => {
      const token = localStorage.getItem('token')
      if (!token) {
          throw new Error('Token non trouvé');
        }
        axios.get(APP_URL+'livres/'+bookId,{
            headers: {
               'Content-Type': 'application/ld+json',
              // 'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`, // Assurez-vous d'avoir un token valide
            },
          }).then((res) => {
            setTitre(res.data.titre);
            setType(res.data.type);
            console.log(res.data)
          }).catch((error) => {
            console.log(error);
          })

    },[])

    return (
        <>
            <center>


                <br /><br />
                <h2>Etape 3 Ajoutons Le fichier {type == 'AudioBook' ? <>(la video d'introduction du livre)</>:<>(le chapitre d'introduction du livre)</>} </h2>
                <h5> <span className="fa fa-book"></span> {titre}</h5>
    <br /><br />
    <Upload
      accept=".pdf,.mp3"
      customRequest={(file) => {
          const token = localStorage.getItem('token')
          if (!token) {
              throw new Error('Token non trouvé');
            }
            return axios.post(`${APP_URL}add-file-book/${bookId}`, {
              file,
          }, {
              headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': `Bearer ${token}`,
                      }
    }).then((response) => {
            console.log(response.data)
            setFileList(response.data);
          }).catch((error) => {
            console.log(error.response.data);
          });
        }}

        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        name="file"
       
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>

      {selected?<>
        <Button className="btn btn-primary" onClick={() => {
          
          toast.success('Livre bien creer');
      
          window.location.reload()
        
        } }>Terminer</Button>

      </>:<></>}

      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
                
            </center>
        
        </>
    );
}