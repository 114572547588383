import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Spin, Table } from 'antd';
import { fetchReadingStats } from '../actions/StatisticsActions';

const ReadingList = ({ readings, loading, error, currentPageNumber, totalPages, fetchReadingStats }) => {
  useEffect(() => {
    fetchReadingStats(currentPageNumber);
  }, [fetchReadingStats, currentPageNumber]);

  const handlePageClick = (data) => {
    fetchReadingStats(data.selected + 1);
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'User', dataIndex: 'user', key: 'user' },
    { title: 'Role', dataIndex: 'role_user', key: 'role_user' },
    { title: 'Livre', dataIndex: 'livre', key: 'livre' },
    { title: 'Temps de Lecture', dataIndex: 'temps_lecture', key: 'temps_lecture' },
    { title: 'Nombre de Sessions', dataIndex: 'nb_sessions', key: 'nb_sessions' },
    { title: 'Date d\'Enregistrement', dataIndex: 'date_enregistrement', key: 'date_enregistrement' },
    { title: 'Date de Mise à Jour', dataIndex: 'date_update', key: 'date_update' }
  ];

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <p>Erreur: {error}</p>;
  }

  return (
    <div>
      <h2>Liste des Temps de Lectures</h2>
      <Table 
        dataSource={readings} 
        columns={columns} 
        rowKey="id" 
        pagination={false} 
      />
      <ReactPaginate
        previousLabel={'Précédent'}
        nextLabel={'Suivant'}
        breakLabel={'...'}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  readings: state.readings.readings,
  loading: state.readings.loading,
  error: state.readings.error,
  currentPageNumber: state.readings.currentPageNumber,
  totalPages: state.readings.totalPages,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReadingStats: (page) => dispatch(fetchReadingStats(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReadingList);
