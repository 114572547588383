const initialState = {
    books: [],
    updating: false,
    loading: "",
    error : null,
}

const bookReducer = (state = initialState, action) => {
    switch(action.type){
        case 'CREATE_BOOK_SUCCESS':
            return {
              ...state,
              books: [...state.books, action.payload],
              loading: false,
              error: null,
            };
            case 'CREATE_BOOK_FAILURE':
                return {
                  ...state,
                  loading: false,
                  error: action.payload,
                };
        case "DELETE_BOOK_SUCCESS":
            return{
                ...state,
                books : state.books.filter((book)=> book.id !== action.payload)
            };
            case "DELETE_BOOK_FAILURE":
                return{
                    ...state,
                    loading: false,
                    error: action.payload,
                };
        case "FETCH_BOOKS_SUCCESS":
            return {
                ...state,
                books: action.payload,
                loading: false,
                error: null,
              };
        case "FETCH_BOOKS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload,
              };
        case 'UPDATE_BOOK_SUCCESS':
            return {
                ...state,
                updating: false,
                loading: false,
                error: null,
            };
       case 'UPDATE_BOOK_FAILURE':
            return {
                ...state,
                updating: false,
                loading: false,
                error: action.payload,
            };

              default:
                return state;  
    }
}

export default bookReducer