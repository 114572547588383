import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser } from "../actions/UserActions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import img from '../assets/Bibliotheque-VilledeEastAngus.jpg'

import "./Login.css";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Loading from "./Loading";
import { APP_URL } from "../URL";
import { Spin } from "antd";

const Login = ({ loginUser }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (token && tokenExpiration) {
      const currentTime = Date.now();
      if (currentTime < parseInt(tokenExpiration)) {
        // Le token est encore valide, redirigez l'utilisateur
        navigate("/admin");
      }
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async () => {
    try {
      if (!formData.email || !formData.password) {
        toast.error("Veuillez remplir tous les champs.");
        return;
      }
      setLoading(true);

      const requestData = {
        username: formData.email,
        password: formData.password,
      };
      const response = await axios.post(
        APP_URL + "login_check",
        requestData
      );
      const { token } = response.data;
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000;
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpiration", expirationTime.toString());
      loginUser({ email: formData.email, token });
      await new Promise((resolve) => setTimeout(resolve, 0));
      navigate("/admin");
      toast.success("Connexion réussie !");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Identifiants incorrects. Veuillez réessayer.");
        } else {
          toast.error("Erreur lors de la connexion :", error.response.data);
        }
      } else {
        toast.error("Erreur lors de la connexion. Veuillez réessayer.");
      }
    } finally {
      setLoading(false);
      setFormData({
        ...formData,
        email: "",
        password: "",
      });
    }
  };

  return (
    <div>
      <div className="login-container" style={{ background: "white" }}>
        <div class="text-center" style={{ fontFamily: "arial" }}>
          <h3>
            <b style={{ color: "#06dc0d" }}>Awoulawoula</b> admin
          </h3>
        </div>
        <form>
          <label className="label">
            Adresse e-mail:
            <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="common-input form-control"
            />
          </label>
          <br />
          <label className="label">
            Mot de passe:
            <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="common-input form-control"
            />
          </label>
          <br />
          <button
            type="button"
            onClick={handleLogin}
            className={`button ${loading ? "loading" : ""}`}
            disabled={loading}
          >
            {loading ? <Spin size="small" /> : "Se connecter"}
          </button>
        </form>
        {/* Lien pour réinitialiser le mot de passe */}
        <div className="reset-password-link">
          <Link to="/reset-password">Mot de passe oublié ?</Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user ? state.user : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: (user) => dispatch(loginUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
