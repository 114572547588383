import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { fetchCategories } from '../actions/CategoryActions';
import { connect } from 'react-redux';

import { useRecoilState } from "recoil";
import { bookIdState } from "../actions/Atom";
import { updateBook } from '../actions/BooksActions';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const EditBookModal = ({ show, bookData, onHide, categories, fetchCategories, updateBook }) => {
  const [bookId, setBookId] = useRecoilState(bookIdState);
 console.log(bookData);
  const [editedBook, setEditedBook] = useState({
    titre: '',
    description: '',
    genre: '',
    annee_parution: '',
    isbm: '',
    editeur: '',
    langue: '',
    nb_page: '',
    age_min: 0,
    resume: '',
    type: 'eBook',
    categories: [] // Initialisé comme un tableau vide par défaut
  });

  const [selectedDate, setSelectedDate] = useState(null); // Utilisation de null comme valeur par défaut
  console.log(selectedDate);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (bookData) {
      setEditedBook({ ...bookData, categories: [] });
      setSelectedDate(bookData.annee_parution ? dayjs(bookData.annee_parution) : null); // Convertir la date en objet dayjs
    }
  }, [bookData]);

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
  
    setEditedBook((prevBook) => {
      const currentCategories = prevBook.categories || []; // Assurez-vous que c'est un tableau
  
      const updatedCategories = checked
        ? [...currentCategories, value]
        : currentCategories.filter((id) => id !== value);
  
      return {
        ...prevBook,
        categories: updatedCategories,
      };
    });
  };
  
  const handleEdit = () => {
    const formattedDate = selectedDate && dayjs.isDayjs(selectedDate) ? selectedDate.format('YYYY-MM-DD') : '';
    const bookFormData = {
      ...editedBook,
      annee_parution: formattedDate,
    };
    console.log(bookFormData);
    updateBook(bookId, bookFormData);
    onHide();
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedBook((prevBook) => ({ ...prevBook, [name]: value }));
  };
 
  return (
    <Modal show={show} onHide={onHide} centered size="lg" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', marginTop: '100px', marginLeft: '130px' }}>
      <Modal.Header closeButton>
        <Modal.Title>Modifier le livre</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Form>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="titre" className="form-label">
                Titre du livre
              </label>
              <input
                type="text"
                className="form-control"
                id="titre"
                name="titre"
                value={editedBook.titre || ''}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="age_min" className="form-label">
                Âge minimum requis
              </label>
              <input
                type="number"
                className="form-control"
                id="age_min"
                name="age_min"
                min="0"
                value={editedBook.age_min}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="genre" className="form-label">
                Genre du livre
              </label>
              <input
                type="text"
                className="form-control"
                id="genre"
                name="genre"
                value={editedBook.genre}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="annee_parution" className="form-label">
                Année de parution
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <DatePicker
                className="form-control"
                id="annee_parution"
                name="annee_parution"
                value={selectedDate} // Utiliser la date sélectionnée
                onChange={(date) => setSelectedDate(date)}   
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="isbm" className="form-label">
                ISBN
              </label>
              <input
                type="text"
                className="form-control"
                id="isbm"
                name="isbm"
                value={editedBook.isbm}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="editeur" className="form-label">
                Éditeur du livre
              </label>
              <input
                type="text"
                className="form-control"
                id="editeur"
                name="editeur"
                value={editedBook.editeur}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="langue" className="form-label">
                Langue du livre
              </label>
              <input
                type="text"
                className="form-control"
                id="langue"
                name="langue"
                value={editedBook.langue}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="nb_page" className="form-label">
                Nombre de pages
              </label>
              <input
                type="number"
                className="form-control"
                id="nb_page"
                name="nb_page"
                min="0"
                value={editedBook.nb_page}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="resume" className="form-label">
                Résumé du livre
              </label>
              <textarea
                className="form-control"
                id="resume"
                name="resume"
                value={editedBook.resume}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="description" className="form-label">
                Description du livre
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={editedBook.description}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="categories" className="form-label">
                Catégories
              </label>
              <div>
                {Array.isArray(categories.categories) &&
                  categories.categories.map((category) => (
                    <div key={category.id} className="form-check">
                      <input
                        type="checkbox"
                        id={`category_${category.id}`}
                        name={`category_${category.id}`}  
                        value={category.id.toString()} // Assurez-vous que la valeur est une chaîne
                        checked={editedBook.categories && editedBook.categories.includes(category.id.toString())} // Vérifiez si l'ID de catégorie est inclus dans les catégories éditées
                        onChange={handleCategoryChange}
                        className="form-check-input"
                      />
                      <label
                        htmlFor={`category_${category.id}`}
                        className="form-check-label"
                      >
                        {category.nom}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="type" className="form-label">
                Type de livre
              </label>
              <select
                className="form-select"
                id="type"
                name="type"
                value={editedBook.type}
                onChange={handleChange}
              >
                <option value="eBook">Ebook</option>
                <option value="AudioBook">AudioBook</option>
              </select>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleEdit}>
          Enregistrer les modifications
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories, 
  books: state.books 
});
  
const mapDispatchToProps = (dispatch) => ({
  fetchCategories: () => dispatch(fetchCategories()),
  updateBook: (bookId, updatedBook) => dispatch(updateBook(bookId, updatedBook)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(EditBookModal);
