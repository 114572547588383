const initialState = {
    categories: [],
    updating: false,
    loading: "",
    error : null,
}

const categoryReducer = (state =initialState, action)=>{
    switch (action.type) {
        case "CREATE_CATEGORY_SUCCESS":
          return {
            ...state,
            categories: [...state.categories, action.payload],
            loading: false,
            error: null,
          };
          case "CREATE_CATEGORY_FAILURE":
            return {
              ...state,
              loading: false,
              error: action.payload,
            };
            case 'FETCH_CATEGORIES_SUCCESS':
      return {
        ...state,
        categories: action.payload, // Stockage des données dans le store Redux
        loading: false,
        error: null,
      };
    case 'FETCH_CATEGORIES_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case "DELETE_CATEGORY_SUCCESS":
        return{
            ...state,
            categories : state.categories.filter((categorie)=> categorie.id !== action.payload)
        };
        case "DELETE_CATEGORY_FAILURE":
            return{
                ...state,
                loading: false,
                error: action.payload,
            };

            case 'UPDATE_CATEGORY_SUCCESS':
              return {
                  ...state,
                  updating: false,
                  loading: false,
                  error: null,
              };
         case 'UPDATE_CATEGORY_FAILURE':
              return {
                  ...state,
                  updating: false,
                  loading: false,
                  error: action.payload,
              };
        default:
          return state;
      }
}

export default categoryReducer;