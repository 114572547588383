import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateCategory } from '../actions/CategoryActions';
import { APP_URL } from '../URL';
import axios from 'axios';

const EditCategoryModal = ({ show, onHide, category, updateCategory, categoryId, refreshCategoryList }) => {
    const [loading, setLoading] = useState(true);
    const [editedCategory, setEditedCategory] = useState({ nom: '', description: '' });

    useEffect(() => {
        const fetchCategories = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Token non trouvé');
            }
            try {
                const response = await axios.get(APP_URL + 'categories/' + categoryId, {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setEditedCategory(response.data.datas);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        fetchCategories();
    }, [categoryId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedCategory((prevCategory) => ({
            ...prevCategory,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await updateCategory(categoryId, editedCategory);
            onHide();
            refreshCategoryList(); // Actualiser la liste des catégories après la modification
        } catch (error) {
            console.error('Erreur lors de la mise à jour du chapitre :', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Modifier la catégorie</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <Form.Group className="mb-3" controlId="formCategoryName">
                        <Form.Label>Nom de la catégorie</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrez le nom de la catégorie"
                            name="nom"
                            value={editedCategory.nom}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCategoryDescription">
                        <Form.Label>Description de la catégorie</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Entrez la description de la catégorie"
                            name="description"
                            value={editedCategory.description}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Enregistrer les modifications
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateCategory: (categoryId, category) => dispatch(updateCategory(categoryId, category)),
});

export default connect(null, mapDispatchToProps)(EditCategoryModal);
