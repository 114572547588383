import { Button, Modal } from 'antd';

const ModalDeleteChapter = ({ open, onCancel, onConfirm }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      title="Confirmation de suppression"
    >
      <div>
        <p>
          Êtes-vous sûr de vouloir supprimer ce chapitre ?
        </p>
        <div style={{ textAlign: 'right' }}>
          <Button style={{ marginRight: 10 }} onClick={onCancel}>
            Annuler
          </Button>
          <Button type="danger" style={{ backgroundColor: 'red', borderColor: 'red' }} onClick={onConfirm}>
            Supprimer
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteChapter;
