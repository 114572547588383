
import { Button, Modal, Spin } from 'antd';
import { useRecoilState } from 'recoil';
import { modalCategoryState, modalDataCategoeyState } from '../../actions/Atom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { APP_URL } from '../../URL';

export default function ShowCategory({book}){

  const [isModalOpenCategory, setIsModalOpenCategory] = useRecoilState(modalCategoryState);
  const [modalData, setModalData] = useRecoilState(modalDataCategoeyState);
  
  const [categories, setCategies] = useState([]);
  const token = localStorage.getItem('token');
  const [titreLivre, setTitreLivre] = useState('');
  const [loading, setLoading] = useState(true);

  const showModal = () => {
    setIsModalOpenCategory(true);
  };
  const handleOk = () => {
    setIsModalOpenCategory(false);
  };
  const handleCancel = () => {
    setIsModalOpenCategory(false);
  };

  useEffect(() => {
    setLoading(true);
    setTitreLivre(modalData.titre); //modalData.id
    axios.get(APP_URL+'get-category-for-book/'+modalData.id, {
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${token}`,
        },
      }).then((response) => {

        setCategies(response.data);
       
        setLoading(false);
        
    }).catch((error) => console.log(error));
  },[isModalOpenCategory]);

    return (
        <>
     
      <Modal title={`Categories du livre "${titreLivre}"`} open={isModalOpenCategory} onOk={handleOk} onCancel={handleCancel}>
       
       {loading?<>
            <br /><br />
            <center>
                <Spin size='large' />
            </center>
           <br /><br />
       </>:<>
       
       {categories.map((item) => {
            return(
                <>
                    {item.nom} <br />
                </>
            );
        })}

       </>}
       
       
      </Modal>
        </>
    );
}