// CategoryForm.js
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createCategory } from '../actions/CategoryActions';
import { useNavigate } from 'react-router-dom';
const CategoryForm = ({ createCategory }) => {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState({
    nom: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Appeler l'action Redux pour créer une catégorie
    createCategory(categoryData);
    setCategoryData({
        nom: '',
        description: '',
      });
      navigate('/admin/book-category')
  };

  return (
    <div>
      <h2>Créer une nouvelle catégorie</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="nom" className="form-label">
            Nom de la catégorie
            <span style={{ color: "red", paddingLeft: "5px",fontSize:"20px" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="nom"
            name="nom"
            value={categoryData.nom}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description de la catégorie
            <span style={{ color: "red", paddingLeft: "5px",fontSize:"20px" }}>*</span>
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={categoryData.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Créer la catégorie
        </button>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createCategory: (categoryData) => dispatch(createCategory(categoryData)),
});

export default connect(null, mapDispatchToProps)(CategoryForm);
