import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../actions/UserActions";
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import TokenChecker from "../utils/TokenChecker";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import BookList from "./BookList";
import CategoryForm from "./CategoryForm";
import CategoryList from "./CategoryList";
import BookForm from "./BookForm";
import UsersList from "./UsersList";
import Etape2Livre from "./Etape2ivre";
import UserRestoreList from "./UserRestoreList";
import { fetchStatistics } from "../actions/StatisticsActions";
import MonthlyReadsChart from "./MonthlyReadsChart";
import { Table } from 'antd';
import BookRatingChart from "./BookRatingChart";
import ReadingProgressChart from "./ReadingProgressChart";
import UserActivityChart from "./UserActivityChart";
import ReadingList from "./ReadingList";
import ProgressionList from "./ProgressionList";


const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Nom',
    dataIndex: 'nom',
    key: 'nom',
  },
];

const columns1 = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Nom',
    dataIndex: 'nom',
    key: 'nom',
  },
];

const EnfantTable = ({ enfantList }) => {
  return (
    <div className="box_general padding_bottom">
      <h1>Liste Enfants</h1>
      <Table dataSource={enfantList} columns={columns} />
    </div>
  );
};

const ParentsTable = ({ parentList }) => {
  return (
    <div className="box_general padding_bottom">
      <h1>Liste Parents</h1>
      <Table dataSource={parentList} columns={columns1} />
    </div>
  );
};

const AdminPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState('/admin');
  const { nb_livre, nb_enfant, nb_parent, parent_list, enfant_list, loading, error } = useSelector((state) => state.statistics);
  const [showEnfantList, setShowEnfantList] = useState(false);
  const [showParentList, setShowParentList] = useState(false);
  const [showMonthlyReadsChart, setShowMonthlyReadsChart] = useState(true);
  const [showBookRatingChart, setShowBookRatingChart] = useState(true);
  const [showReadingProgressChart, setReadingProgressChart] = useState(true);
  const [showUserActivityChart, setUserActivityChart] = useState(true);
  const [showReadingList, setReadingList] = useState(true);
  const [showProgressionList, setProgressionList] = useState(true);
  
  
  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleItemClick = (path) => {
    console.log(path);
    setCurrentPath(path);
    // Ajoutez d'autres actions ou logique si nécessaire
  };

  useEffect(() => {
    dispatch(fetchStatistics());
  }, [dispatch]);


  useEffect(() => {
    // Afficher MonthlyReadsChart lorsque showEnfantList redevient false
    if (!showEnfantList ) {
      setShowMonthlyReadsChart(true);
      setShowBookRatingChart(true);
      setUserActivityChart(true);
      setReadingProgressChart(true);
      setReadingList(true)

    }
  }, [showEnfantList]);

  useEffect(() => {
    // Afficher MonthlyReadsChart lorsque showParentList redevient false
    if (!showParentList ) {
      setShowMonthlyReadsChart(true);
      setShowBookRatingChart(true);
      setUserActivityChart(true);
      setReadingProgressChart(true);
      setReadingList(true)

    }
  }, [showParentList]);

  return (
    <>
      <TokenChecker redirectTo="/login" />
      <body className="fixed-nav sticky-footer" id="page-top">
        <Sidebar currentPath={currentPath} setCurrentPath={handleItemClick} handleLogout={handleLogout} />
        <div className="content-wrapper" style={{ marginBottom: '0px', height: "100px" }}>
          <Header />
          <div className="container-fluid">
            {currentPath === '/admin' && (
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">My Dashboard</li>
                </ol>
                <div className="row">
                  <div className="col-xl-4 col-sm-6 mb-3">
                    <div className="card dashboard text-white bg-primary o-hidden h-100">
                      <div className="card-body">
                        <div class="card-body-icon">
                          <i class="fa fa-fw fa-book"></i>
                        </div>
                        <div className="mr-5"><h5> {nb_livre} Total Livres!</h5></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6 mb-3">
                    <div className="card dashboard text-white bg-danger o-hidden h-100" onClick={() => { setShowEnfantList(!showEnfantList); setShowMonthlyReadsChart(false);setShowBookRatingChart(false); setUserActivityChart(false); setReadingProgressChart(false); setReadingList(false) }}>
                      <div className="card-body">
                        <div className="card-body-icon">
                          <i className="fa fa-fw fa-child"></i>
                        </div>
                        <div className="mr-5"><h5> {nb_enfant} Total Enfants!</h5></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6 mb-3">
                    <div className="card dashboard text-white bg-success o-hidden h-100" onClick={() => { setShowParentList(!showParentList); setShowMonthlyReadsChart(false);setShowBookRatingChart(false); setUserActivityChart(false); setReadingProgressChart(false);setReadingList(false) }}>
                      <div className="card-body">
                        <div className="card-body-icon">
                          <i className="fa fa-fw fa-child"></i>
                        </div>
                        <div className="mr-5"><h5>{nb_parent} Total Parents!</h5></div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xl-3 col-sm-6 mb-3">
                    <div className="card dashboard text-white bg-warning o-hidden h-100">
                      <div className="card-body">
                        <div className="card-body-icon">
                          <i className="fa fa-fw fa-star"></i>
                        </div>
                        <div className="mr-5"><h5>11 New Reviews!</h5></div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            <h2></h2>
            <div className="box_general padding_bottom">
              {currentPath === '/admin' && (
                <div className="header_box version_2">
                  <h2><i className="fa fa-bar-chart"></i> Statistic</h2>
                </div>
              )}
              <div>
                <div class="row">
                  <div class="col-lg-12 mb-3 mt-5">
                    {showMonthlyReadsChart && currentPath === '/admin' && <MonthlyReadsChart />}
                  </div>
                  {/* <div class="col-lg-6  mb-3 mt-5">
                    {showBookRatingChart && currentPath === '/admin' && <BookRatingChart />}
                  </div> */}
                </div>
                <div class="row">
                <div class="col-lg-12  mb-3 mt-5">
                    {showReadingList && currentPath === '/admin' && <ReadingList />}
                  </div>
                  {/* <div class="col-lg-6  mb-3 mt-5">
                     {showUserActivityChart && currentPath === '/admin' && <UserActivityChart />}
                  </div>
                  <div class="col-lg-6  mb-3 mt-5">
                    {showReadingProgressChart && currentPath === '/admin' && <ReadingProgressChart />}
                  </div> */}
                 
                  {/* <div class="col-lg-6  mb-3 mt-5">
                    {showProgressionList && currentPath === '/admin' && <ProgressionList />}
                  </div> */}
                  
                </div>

                {/* Le reste de votre code */}
                {currentPath === "/admin/create-books" && <BookForm />}
                {currentPath === "/admin/book-list" && <BookList />}
                {currentPath === "/admin/create-category" && <CategoryForm />}
                {currentPath === "/admin/book-category" && <CategoryList />}
                {currentPath === "/admin/user-list" && <UsersList />}
                {currentPath === "/admin/step2/:book_id" && <Etape2Livre />}
                {currentPath === "/admin/user-delete" && <UserRestoreList />}
                {showEnfantList && <EnfantTable enfantList={enfant_list} />}
                {showParentList && <ParentsTable parentList={parent_list} />}
              </div>
              <canvas id="myAreaChart" width="100%" height="30" style={{ margin: '45px 0 15px 0' }}></canvas>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user ? state.user : {},
  };
};

export default connect(mapStateToProps, { logoutUser })(AdminPanel);
