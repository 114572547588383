const initialState = {
    bookread: [],
    loading: false,
    error: null,
  };

  const bookReadStatsReducer = (state = initialState, action)=>{
    switch( action.type){
        case 'FETCH_STATISTICS_BOOK_READ_SUCCESS': 
        return {
            ...state,
            loading: false,
            bookread: action.payload.datas,
          };
        case 'FETCH_STATISTICS_BOOK_READ_FAILURE':
        return{
            ...state,
            loading: false,
            error : action.payload.error
        };
        default:
          return state;

    }

  }

  export default bookReadStatsReducer;