import { atom } from "recoil";

// Identifiants
export const bookIdState = atom({
    key:'book_id',
    default: 0
});

export const categoryIdState = atom({
    key:'category_id',
    default: 0
});

export const userIdState = atom({
    key:'user_id',
    default: 0
});

export const chapIdState = atom({
    key:'chap_id',
    default:0,
});

// Chargement
export const loadingBooksListState = atom({
    key:'loading_book_list',
    default:true,
});

// Vue secondaire
export const subViewState = atom({
    key:'sub_view',
    default:'intro'
});

// Modals
export const modaldeleteBook = atom({
    key:'modal_delete_book',
    default:false,
});

export const modalDataDeleteBook = atom({
    key:'modal_data_delete_book',
    default:[],
});

export const ModalDeleteChap = atom({
    key:'modal_delete_chapter',
    default:false,
});

export const modalDataDeleteChapter = atom({
    key:'modal_data_delete_chapter',
    default:[],
});

export const ModalUpdateChap = atom({
    key:'modal_update_chapter',
    default:false,
});

export const modalDataUpdateChapter = atom({
    key:'modal_data_update_chapter',
    default:[],
});

export const modalCategoryState = atom({
    key:'modal_category_state',
    default:false,
});

export const modalDataCategoeyState = atom({
    key:'modal_data_category',
    default:[],
});

export const modalBookUpdateState = atom({
    key:'modal_book_update_state',
    default:false,
});

export const modalDataBookUpdateState = atom({
    key:'modal_data_book_update_state',
    default:[],
});

export const modalCoverUpdateState = atom({
    key:'modal_cover_update_state',
    default:false,
});

export const modalDataCoverUpdateState = atom({
    key:'modal_data_cover_update_state',
    default:[],
});

export const modalChapterUpdateState = atom({
    key:'modal_chapter_update_state',
    default:false,
});

export const modalFileChapterUpdateState = atom({
    key:'modal_file_chapter_update_state',
    default:false,
});

export const modalDataChapterUpdateState = atom({
    key:'modal_data_chapter_update_state',
    default:[],
});

// Modal user
export const modalUserDelete = atom({
    key:'modal_user_delete',
    default:false,
});

export const modalDataUserDelete = atom({
    key:'modal_data_user_delete',
    default:[],
});

export const modalUserDetails = atom({
    key:'modal_user_details',
    default:false,
});

export const modalDataUserDetails = atom({
    key:'modal_data_user_details',
    default:[],
});

export const modalRestoreUser = atom({
    key:'modal_restore_user',
    default:false,
});

export const modalDataRestoreUser = atom({
    key:'modal_data_restore_user',
    default:[],
});

export const modaldeleteCategory = atom({
    key:'modal_delete_category',
    default:false,
});

export const modalDataDeleteCategory = atom({
    key:'modal_data_delete_category',
    default:[],
});

export const modalCategoryUpdateState = atom({
    key:'modal_category_update_state',
    default:false,
});

export const modalDataCategoryUpdateState = atom({
    key:'modal_data_category_update_state',
    default:[],
});
