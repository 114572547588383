const initialState = {
  resetCodeVerified: false,
  resetEmail: '',
  resetSuccess: false,
  error: '',
};

const resetpasswordReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'REQUEST_PASSWORD_RESET_SUCCESS':
          return {
              ...state,
              resetEmail: action.payload.email,
              error: '',
              resetSuccess: false
          };
      case 'VERIFY_RESET_CODE_SUCCESS':
          return {
              ...state,
              resetCodeVerified: true,
              resetSuccess: true,
              error: ''
          };
      case 'RESET_PASSWORD_SUCCESS':
          return {
              ...state,
              resetSuccess: true,
              error: ''
          };
      case 'REQUEST_PASSWORD_RESET_FAILURE':
      case 'VERIFY_RESET_CODE_FAILURE':
      case 'RESET_PASSWORD_FAILURE':
          return {
              ...state,
              error: action.payload,
              resetSuccess: false
          };
      default:
          return state;
  }
};

export default resetpasswordReducer;
