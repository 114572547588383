
import { Modal, Button } from 'react-bootstrap';
const ConfirmationRestoreModal = ({ show, onHide, onConfirm, user }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation de la restoration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {user && (
          <p>
            Êtes-vous sûr de vouloir restore l'utilisateur "{user.nom} {user.prenom} " ?
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="success" onClick={onConfirm}>
          Restorer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationRestoreModal;
