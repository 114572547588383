import React from 'react';
import { createRoot } from 'react-dom/client';
import '../node_modules/bootstrap/dist/js/bootstrap'
import { createStore, applyMiddleware, combineReducers,compose } from 'redux';
import { Provider } from 'react-redux';
import * as thunk from 'redux-thunk';
import App from './App';
import reportWebVitals from './reportWebVitals';
import userReducer from './reducers/userReducer';
import appReducer from './reducers/appReducer'; // Ajoutez l'importation du réducteur d'application
import 'react-toastify/dist/ReactToastify.css';
import categoryReducer from './reducers/categoryReducer';
import bookReducer from './reducers/bookReducer';
import fileReducer from './reducers/fileReducer';
import { RecoilRoot } from 'recoil';
import resetpasswordReducer from './reducers/resetpasswordReducer';
import chapitreReducer from './reducers/chapitreReducer';
import statisticsReducer from './reducers/statisticsReducer';
import readingStatsReducer from './reducers/readingStatsReducer';
import progressionStatsReducer from './reducers/progressionStatsReducer';
import bookReadStatsReducer from './reducers/bookReadStatsReducer';


// Créez le middleware redux-thunk
const customThunkMiddleware = thunk.withExtraArgument();

// Combinez vos réducteurs
const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  categories: categoryReducer,
  books: bookReducer,
  file: fileReducer,
  reset:resetpasswordReducer,
  chapitres: chapitreReducer,
  statistics: statisticsReducer,
  readings: readingStatsReducer,
  progressions: progressionStatsReducer,
  bookread: bookReadStatsReducer

})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Récupérez les données utilisateur depuis le stockage local
const userFromLocalStorage = localStorage.getItem('token');

// Créez le store en appliquant le middleware
const store = createStore(
  rootReducer, // Utilisez le réducteur racine combiné
  { user: { token: userFromLocalStorage || null }, app: {} }, // Assurez-vous de fournir l'état initial pour chaque "slice" du réducteur
  composeEnhancers(applyMiddleware(customThunkMiddleware))
);

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
    <RecoilRoot >
      <App />
    </RecoilRoot>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
