import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Spin, Alert } from "antd";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
} from "chart.js";
import { fetchStasBookByMonths } from "../actions/StatisticsActions";

// Enregistrer les composants nécessaires de Chart.js
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

const MonthlyReadsChart = ({ bookread, loading, error, fetchStasBookByMonths }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    fetchStasBookByMonths();
  }, [fetchStasBookByMonths]);

  useEffect(() => {
    if (bookread && !loading && !error) {
      const months = bookread.map(monthData => Object.keys(monthData)[0]);
      const books = new Set();

      bookread.forEach(monthData => {
        const month = Object.keys(monthData)[0];
        monthData[month].forEach(bookData => {
          books.add(Object.keys(bookData)[0]);
        });
      });

      const bookTitles = Array.from(books);
      const datasets = bookTitles.map(title => ({
        label: title,
        data: months.map(month => {
          const monthData = bookread.find(m => Object.keys(m)[0] === month);
          if (monthData) {
            const book = monthData[month].find(b => Object.keys(b)[0] === title);
            return book ? Object.values(book)[0] : 0;
          }
          return 0;
        }),
        backgroundColor: getRandomColor(),
        borderWidth: 1,
      }));

      setChartData({
        labels: months,
        datasets: datasets,
      });
    }
  }, [bookread, loading, error]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Mois",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Nombre de lectures",
        },
      },
    },
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert message="Erreur" description={error} type="error" showIcon />;
  }

  return chartData ? <ChartContainer data={chartData} options={chartOptions} /> : null;
};

const ChartContainer = ({ data, options }) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.destroy();
    }

    const newChartInstance = new ChartJS(chartRef.current, {
      type: 'bar',
      data: data,
      options: options,
    });

    setChartInstance(newChartInstance);

    return () => {
      newChartInstance.destroy();
    };
  }, [data, options]);

  return <canvas ref={chartRef} id="chart"></canvas>;
};

const mapStateToProps = (state) => ({
  bookread: state.bookread.bookread,
  loading: state.bookread.loading,
  error: state.bookread.error,
});

const mapDispatchToProps = {
  fetchStasBookByMonths,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyReadsChart);
