
import { Modal, Button } from 'react-bootstrap';
const DeleteCategoryModal = ({ show, onHide, onConfirm, category }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation de suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {category && (
          <p>
            Êtes-vous sûr de vouloir supprimer cette categorie "{category.nom}  " ?
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCategoryModal;
